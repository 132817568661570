import { gql } from '@apollo/client';
import { TariffGroup } from 'tariffGroups/models/tariffGroup';

interface TariffGroupConnection {
  afterCursor: string | null;
  beforeCursor: string | null;
  entries: TariffGroup[];
}

export interface TariffGroupList {
  powerDistributionUnitTariffGroups: TariffGroupConnection;
}

export const TariffGroupTypename = 'PowerDistributionUnitTariffGroupConnection';

export const TARIFF_GROUPS_LIST_QUERY = gql`
  query powerDistributionUnitTariffGroups(
    $after: String
    $before: String
    $pageSize: Int
  ) {
    powerDistributionUnitTariffGroups(
      after: $after
      before: $before
      pageSize: $pageSize
    ) {
      entries {
        id
        tariffGroupConsumptionCosts {
          energyTariff
          energyUsageTariffOnPeak
          energyUsageTariffOffPeak
          energyDistributionServiceTariff
          energyDistributionServiceTariffOnPeak
          energyDistributionServiceTariffOffPeak
        }
        tariffGroupDemandCosts {
          all
          onPeak
          offPeak
        }
        tariffGroupConsumptionGroup
        tariffGroupModality
        tariffGroupValidityEndDate
        tariffGroupValidityStartDate
        powerDistributionUnit {
          id
          powerDistributionUnitLegalName
        }
      }
      beforeCursor
      afterCursor
    }
  }
`;
