import { Button } from 'ui';
import { Dashboard, DashboardMainHeaderForm } from 'dashboard/components/dashboard';
import UserFormFields, {
  FormFields,
} from 'internalUsers/components/form/UserFormFields';
import { Page } from 'dashboard/components/dashboard/Breadcrumbs';

import { useForm, SubmitHandler, ErrorOption } from 'react-hook-form';
import { ApolloError, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { setFormError } from 'utils/form';
import {
  InternalUserCreated,
  InternalUserCreateMutationVariables,
  INTERNAL_USER_CREATE_MUTATION,
} from 'internalUsers/graphql/internalUserCreateMutation';
import {
  InternalUserList,
  internalUsersTypename,
  INTERNAL_USERS_QUERY,
} from 'internalUsers/graphql/internalUsersQuery';
import useToastContext from 'ui/hooks/useToast';
import { useHistory } from 'react-router';
import { ToastProps } from 'ui/contexts/overlay/Toast';
import { useLoading } from 'ui/contexts/overlay/Loading';
import { useEffect } from 'react';
import { has } from 'lodash';

const DASHBOARD_INTERNAL_USERS_ROUTE = '/dashboard/internal-users';

const BREADCRUMB_PAGES: Page[] = [
  { name: 'Usuário interno', route: DASHBOARD_INTERNAL_USERS_ROUTE, current: false },
  {
    name: 'Novo usuário interno',
    route: null,
    current: true,
  },
];
const TITLE = 'Novo usuário interno';
const CREATE_ERROR_TOAST: ToastProps = {
  text: 'Houve um erro ao tentar criar o usuário',
  title: 'Algo deu errado!',
  variant: 'danger',
};

const CREATE_SUCCESS_TOAST: ToastProps = {
  title: 'Sucesso!',
  variant: 'primary',
  text: 'Sucesso ao cadastrar o Usuário.',
};

export default function CreateInternalUsersPage() {
  const { push } = useHistory();
  const { addToast } = useToastContext();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<FormFields>();
  const { showLoading, closeLoading, LoadingOverlay } = useLoading();

  const [internalUserCreateMutation, { loading }] = useMutation<
    InternalUserCreated,
    InternalUserCreateMutationVariables
  >(INTERNAL_USER_CREATE_MUTATION, {
    onError(error: ApolloError) {
      if (has(error.graphQLErrors[0], 'details')) {
        addToast(CREATE_ERROR_TOAST);
      }

      setFormError(
        error,
        (field: string, error: ErrorOption) => {
          setError(field as keyof FormFields, error);

          setTimeout(() => clearErrors(), 2500);
        },
        t
      );
    },
    onCompleted() {
      addToast(CREATE_SUCCESS_TOAST);
      push(DASHBOARD_INTERNAL_USERS_ROUTE);
    },
  });

  const onSubmit: SubmitHandler<FormFields> = (internalUserCreateInput) => {
    internalUserCreateMutation({
      variables: {
        internalUserCreateInput,
      },
      update(cache, { data }) {
        const existingData = cache.readQuery<InternalUserList>({
          query: INTERNAL_USERS_QUERY,
        });
        cache.writeQuery({
          query: INTERNAL_USERS_QUERY,
          data: {
            internalUsers: {
              __typename: internalUsersTypename,
              afterCursor: existingData?.internalUsers.afterCursor || null,
              beforeCursor: existingData?.internalUsers.beforeCursor || null,
              entries: [
                data?.internalUserCreate,
                ...(existingData?.internalUsers.entries || []),
              ],
            },
          },
        });
      },
    });
  };

  useEffect(() => {
    if (loading) {
      showLoading();
      return;
    }

    closeLoading();
  }, [loading, showLoading, closeLoading]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LoadingOverlay />
      <Dashboard
        dashboardHeader={<div className="h-10" />}
        dashboardMainHeaderTitle={
          <DashboardMainHeaderForm breadcrumbPages={BREADCRUMB_PAGES} title={TITLE}>
            <Button type="submit" size="sm" disabled={loading}>
              Salvar
            </Button>
          </DashboardMainHeaderForm>
        }
      >
        <UserFormFields errors={errors} register={register} />
      </Dashboard>
    </form>
  );
}
