import { gql } from '@apollo/client';
import {
  CooperativeMemberEntityType,
  CooperativeMemberModalityType,
  CooperativeSelect,
} from 'cooperatives/models/cooperativeMember';

interface CooperativeMemberSelectConnection {
  entries: {
    id: number;
    cooperativeMemberLegalName: string;
    cooperativeMemberDocumentId: string;
    cooperativeMemberEntityType: CooperativeMemberEntityType;
    cooperativeMemberModalityType: CooperativeMemberModalityType;
    cooperative: CooperativeSelect;
  }[];
}

export interface CooperativeMembersSelectList {
  cooperativeMembers: CooperativeMemberSelectConnection;
}

export const cooperativeMembersTypename = 'CooperativeMemberConnection';

const MAX_PAGE_SIZE = 100;

export const COOPERATIVE_MEMBERS_SELECT_QUERY_VARIABLES = {
  variables: { pageSize: MAX_PAGE_SIZE },
};

export const COOPERATIVE_MEMBERS_SELECT_QUERY = gql`
  query cooperativeMembers($after: String, $before: String, $pageSize: Int) {
    cooperativeMembers(after: $after, before: $before, pageSize: $pageSize) {
      entries {
        id
        cooperativeMemberLegalName
        cooperativeMemberDocumentId
        cooperativeMemberEntityType
        cooperativeMemberModalityType
        cooperative {
          id
          cooperativeLegalName
        }
      }
    }
  }
`;
