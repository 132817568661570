import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { ErrorOption, SubmitHandler, useForm } from 'react-hook-form';
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import has from 'lodash/has';
import omit from 'lodash/omit';
import { Button } from 'ui';
import { useConfirm } from 'ui/contexts/overlay/Confirm';
import { useLoading } from 'ui/contexts/overlay/Loading';
import { DEFAULT_OVERLAY_CONFIG, ToastProps } from 'ui/contexts/overlay/Toast';
import useToastContext from 'ui/hooks/useToast';
import { OverlayConfig, OverlayProps } from 'ui/models/overlay';
import { setFormError, formatDateToSubmitDateString } from 'utils/form';
import { updateCacheById } from 'graphql/apollo/cache';
import { Dashboard, DashboardMainHeaderForm } from 'dashboard/components/dashboard';
import { Page } from 'dashboard/components/dashboard/Breadcrumbs';
import DeleteButton from 'icmsTaxes/components/DeleteButton';
import IcmsTaxFormFields, {
  FormFields,
  FORM_FIELDS,
  IcmsRangesFeeType,
} from 'icmsTaxes/components/form/IcmsTaxFields';
import {
  IcmsTaxUpdated,
  IcmsTaxUpdateMutationVariables,
  ICMS_TAX_UPDATE_MUTATION,
} from 'icmsTaxes/graphql/icmsTaxUpdateMutation';
import { ICMS_TAX_QUERY, IcmsTaxTypename } from 'icmsTaxes/graphql/icmsTaxQuery';
import {
  IcmsTaxDeleteMutationVariables,
  ICMS_TAX_DELETE_MUTATION,
} from 'icmsTaxes/graphql/icmsTaxDeleteMutation';

const DASHBOARD_ICMS_TAXES_ROUTE = '/dashboard/taxes-fees/icms-taxes';

const BREADCRUMB_PAGES: Page[] = [
  {
    name: 'ICMS',
    route: DASHBOARD_ICMS_TAXES_ROUTE,
    current: false,
  },
  {
    name: 'Edição de ICMS',
    route: null,
    current: true,
  },
];

const TITLE = 'Edição de ICMS';

const UPDATE_ERROR_TOAST: ToastProps = {
  text: 'Houve um erro ao tentar atualizar o ICMS',
  title: 'Algo deu errado!',
  variant: 'danger',
};
const UPDATE_SUCCESS_TOAST: ToastProps = {
  title: 'Sucesso!',
  variant: 'primary',
  text: 'Sucesso ao atualizar o ICMS.',
};
const FETCH_ERROR_TOAST: ToastProps = {
  title: 'Algo deu errado!',
  variant: 'danger',
  text: 'Não foi possível carregar o ICMS',
};

export default function EditIcmsTaxesPage() {
  const { ConfirmOverlay, showConfirm, closeConfirm } = useConfirm();
  const { id } = useParams<{ id: string }>();
  const { push } = useHistory();
  const { addToast } = useToastContext();
  const { t } = useTranslation();

  const { showLoading, closeLoading, LoadingOverlay } = useLoading();

  const [icmsTaxUpdateMutation, { loading: updateLoading }] = useMutation<
    IcmsTaxUpdated,
    IcmsTaxUpdateMutationVariables
  >(ICMS_TAX_UPDATE_MUTATION, {
    onError(error: ApolloError) {
      if (has(error.graphQLErrors[0], 'details')) {
        addToast(UPDATE_ERROR_TOAST);
      }

      setFormError(
        error,
        (field: string, error: ErrorOption) => {
          setError(field as keyof FormFields, error);

          setTimeout(() => clearErrors(), 2500);
        },
        t
      );
    },
    onCompleted() {
      addToast(UPDATE_SUCCESS_TOAST);
      push(DASHBOARD_ICMS_TAXES_ROUTE);
    },
  });

  const [icmsTaxDeleteMutation, { loading: deleteLoading }] =
    useMutation<IcmsTaxDeleteMutationVariables>(ICMS_TAX_DELETE_MUTATION, {
      onError() {
        closeConfirm();
        setConfirmOverlayProps(DEFAULT_OVERLAY_CONFIG);
        addToast(UPDATE_ERROR_TOAST);
      },
      onCompleted() {
        closeConfirm();
        setConfirmOverlayProps(DEFAULT_OVERLAY_CONFIG);
        addToast(UPDATE_SUCCESS_TOAST);
        push(DASHBOARD_ICMS_TAXES_ROUTE);
      },
    });

  const [
    icmsTaxQuery,
    { data, loading: fetchIcmsTaxLoading, error: fetchIcmsTaxError },
  ] = useLazyQuery(ICMS_TAX_QUERY, {
    variables: {
      icmsId: id,
    },
  });

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
    setValue,
    control,
  } = useForm<FormFields>();

  useEffect(() => {
    if (fetchIcmsTaxError) {
      addToast(FETCH_ERROR_TOAST);
    }
  }, [addToast, fetchIcmsTaxError]);

  useEffect(() => {
    if (data?.icmsTax) {
      const { icmsValidityStartDate, icmsValidityEndDate } = data.icmsTax;
      const icmsFields = Object.assign(
        omit(
          data.icmsTax,
          'icmsValidityStartDate',
          'icmsValidityEndDate'
        ) as FormFields,
        {
          icmsValidity: [
            new Date(icmsValidityStartDate),
            new Date(icmsValidityEndDate),
          ],
        }
      );

      FORM_FIELDS.forEach((field) => {
        if (field === 'icmsRangesFee') {
          const icmsRangesFee: IcmsRangesFeeType = [];

          icmsFields['icmsRangesFee'].map((rangeFee) =>
            icmsRangesFee.push({
              consumptionRangePercentage: rangeFee.consumptionRangePercentage,
              consumptionKwhStartRange: rangeFee.consumptionKwhStartRange,
              consumptionKwhEndRange: rangeFee.consumptionKwhEndRange,
            })
          );

          return setValue(field, icmsRangesFee);
        }

        return setValue(field, icmsFields[field]);
      });
    }
  }, [data, setValue]);

  useEffect(() => {
    icmsTaxQuery();
  }, [icmsTaxQuery, id]);

  const [confirmOverlayProps, setConfirmOverlayProps] = useState<OverlayProps>(
    DEFAULT_OVERLAY_CONFIG
  );

  const isLoading = !!(deleteLoading || updateLoading || fetchIcmsTaxLoading);

  useEffect(() => {
    if (isLoading) {
      showLoading();
      return;
    }

    closeLoading();
  }, [isLoading, showLoading, closeLoading]);

  const onSubmit: SubmitHandler<FormFields> = (icmsTaxUpdateInput) => {
    const [validityStart, validityEnd] = icmsTaxUpdateInput.icmsValidity;
    const icmsValidityStartDate = formatDateToSubmitDateString(
      String(validityStart)
    );
    const icmsValidityEndDate = formatDateToSubmitDateString(String(validityEnd));

    icmsTaxUpdateMutation({
      variables: {
        icmsTaxUpdateInput: {
          ...omit(
            icmsTaxUpdateInput,
            'icmsValidity',
            'icmsCountryState',
            'icmsConsumerUnitConsumptionClass'
          ),
          id: Number(id),
          icmsValidityStartDate,
          icmsValidityEndDate,
        },
      },
    });
  };

  const onConfirmDelete = () =>
    icmsTaxDeleteMutation({
      variables: { id: id },
      update(cache) {
        updateCacheById(cache, Number(id), IcmsTaxTypename);
      },
    });

  const onClickDelete = (overlayConfig: OverlayConfig) => {
    setConfirmOverlayProps({
      ...overlayConfig,
      onConfirm: onConfirmDelete,
    });

    showConfirm();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LoadingOverlay />
      <Dashboard
        dashboardHeader={<div className="h-10" />}
        dashboardMainHeaderTitle={
          <DashboardMainHeaderForm title={TITLE} breadcrumbPages={BREADCRUMB_PAGES}>
            {data?.icmsTax && (
              <>
                {' '}
                <DeleteButton onClick={onClickDelete} disabled={isLoading} />
                <Button type="submit" size="sm" disabled={isLoading}>
                  Salvar
                </Button>
              </>
            )}
            <ConfirmOverlay {...confirmOverlayProps} onCancel={closeConfirm} />
          </DashboardMainHeaderForm>
        }
      >
        {data?.icmsTax && (
          <IcmsTaxFormFields
            errors={errors}
            register={register}
            control={control}
            disableCountryStateSelect
            disableConsumerUnitConsumptionClassSelect
          />
        )}
      </Dashboard>
    </form>
  );
}
