import { gql } from '@apollo/client';
import { FinancialAccount } from 'financialAccounts/models/financialAccounts';

export interface FinancialAccountCreateMutationInput {
  financialAccountLegalName: string;
  financialAccountEntityType: string;
  financialAccountDocumentId: string;
  financialAccountAddressPostalCode: number;
  financialAccountAddressCity: string;
  financialAccountAddressDistrict: string;
  financialAccountAddressStreet: string;
  financialAccountAddressComplement: string;
  financialAccountAddressState: string;
}

export interface FinancialAccountMutationVariables {
  financialAccountCreateInput: FinancialAccountCreateMutationInput;
}

export interface FinancialAccountCreated {
  financialAccountCreate: FinancialAccount;
}

export const FINANCIAL_ACCOUNT_CREATE_MUTATION = gql`
  mutation financialAccountCreate(
    $financialAccountCreateInput: FinancialAccountCreateInput!
  ) {
    financialAccountCreate(input: $financialAccountCreateInput) {
      id
      financialAccountLegalName
      financialAccountEntityType
      financialAccountDocumentId
      financialAccountAddressPostalCode
      financialAccountAddressCity
      financialAccountAddressDistrict
      financialAccountAddressStreet
      financialAccountAddressComplement
      financialAccountAddressState
    }
  }
`;
