import { useEffect, useState } from 'react';
import omit from 'lodash/omit';
import { Control, Controller, FieldError, UseFormRegister } from 'react-hook-form';
import TextField from 'ui/form/TextField';
import SelectField from 'ui/form/SelectField';
import { Option } from 'ui/components/form/SelectInput';
import { PowerDistributionUnitSelect } from 'powerDistributionUnits/models/powerDistributionUnit';
import { GenerationUnitSelectModel } from 'generationUnits/models/generationUnit';
import CountryStateSelectField from 'ui/form/CountryStateSelectField';
import { Button } from 'ui';

export type FormErrors = {
  generationUnitId?: FieldError;
  powerDistributionUnitId?: FieldError;
  consumerUnitAddressState?: FieldError;
  cooperativeMemberLegalName?: FieldError;
  consumerUnitPowerDistributionUnitIdentifier?: FieldError;
};

export type FormFields = {
  generationUnitId: number;
  powerDistributionUnitId: number;
  consumerUnitAddressState: string;
  cooperativeMemberLegalName: string;
  consumerUnitPowerDistributionUnitIdentifier: string;
};

export default function ConsumerUnitFormFilters({
  errors,
  control,
  register,
  isLoading,
  generationUnits,
  powerDistributionUnits,
}: {
  errors: FormErrors;
  control: Control<FormFields>;
  isLoading?: boolean;
  register: UseFormRegister<FormFields>;
  generationUnits?: GenerationUnitSelectModel[];
  powerDistributionUnits?: PowerDistributionUnitSelect[];
}) {
  const [powerDistributionUnitOptions, setPowerDistributionUnitOptions] = useState<
    Option<number>[]
  >([]);
  useEffect(
    () =>
      setPowerDistributionUnitOptions(
        (powerDistributionUnits instanceof Array &&
          powerDistributionUnits.map((powerDistributionUnit) => ({
            key: powerDistributionUnit.id,
            value: powerDistributionUnit.powerDistributionUnitLegalName,
          }))) ||
          []
      ),
    [powerDistributionUnits]
  );

  const [generationUnitOptions, setGenerationUnitOptions] = useState<
    Option<number>[]
  >([]);
  useEffect(
    () =>
      setGenerationUnitOptions(
        (generationUnits instanceof Array &&
          generationUnits.map((generationUnit) => ({
            key: generationUnit.id,
            value: generationUnit.generationUnitLegalName,
          }))) ||
          []
      ),
    [generationUnits]
  );

  return (
    <>
      <div className="grid grid-cols-6 gap-4 pb-6">
        <TextField
          error={errors.consumerUnitPowerDistributionUnitIdentifier?.message}
          label="UC"
          id="consumerUnitPowerDistributionUnitIdentifier"
          {...register('consumerUnitPowerDistributionUnitIdentifier')}
          placeholder="Digitar"
        />

        <TextField
          error={errors.cooperativeMemberLegalName?.message}
          label="Cooperado"
          id="cooperativeMemberLegalName"
          {...register('cooperativeMemberLegalName')}
          placeholder="Digitar"
        />

        <Controller
          name="generationUnitId"
          control={control}
          render={({ field }) => (
            <SelectField
              {...omit(field, 'ref')}
              error={errors.generationUnitId?.message}
              label="Unidade Geradora"
              emptyOptionLabel="Selecionar"
              id="generationUnitId"
              options={generationUnitOptions}
              disabled
            />
          )}
        />

        <Controller
          name="powerDistributionUnitId"
          control={control}
          render={({ field }) => (
            <SelectField
              {...omit(field, 'ref')}
              error={errors.powerDistributionUnitId?.message}
              label="Concessionária"
              emptyOptionLabel="Selecionar"
              id="powerDistributionUnitId"
              options={powerDistributionUnitOptions}
            />
          )}
        />

        <Controller
          name="consumerUnitAddressState"
          control={control}
          render={({ field }) => (
            <CountryStateSelectField
              {...omit(field, 'ref')}
              error={errors.consumerUnitAddressState?.message}
              label="UF"
              id="consumerUnitAddressState"
            />
          )}
        />

        <Button
          type="submit"
          size="sm"
          disabled={isLoading}
          className="self-end w-22"
        >
          Filtrar
        </Button>
      </div>
    </>
  );
}
