import {
  FieldError,
  FieldErrors,
  UseFormRegister,
  useFieldArray,
  Control,
  Controller,
} from 'react-hook-form';
import omit from 'lodash/omit';
import TextField from 'ui/form/TextField';
import CountryStateSelectField from 'ui/form/CountryStateSelectField';
import SelectField from 'ui/form/SelectField';
import DatePickerFieldDobleView from 'ui/form/DatePickerFieldDobleView';
import { IcmsConsumerUnitConsumptionClassType } from 'icmsTaxes/models/icmsTaxes';
import { useEffect } from 'react';

export type IcmsRangesFeeType = {
  consumptionRangePercentage: string;
  consumptionKwhStartRange: string;
  consumptionKwhEndRange: string;
}[];

export type FormFields = {
  icmsCountryState: string;
  icmsConsumerUnitConsumptionClass: IcmsConsumerUnitConsumptionClassType;
  icmsValidity: [Date, Date];
  icmsRangesFee: IcmsRangesFeeType;
};

export type FormErrors = {
  icmsCountryState?: FieldError | undefined;
  icmsConsumerUnitConsumptionClass?: FieldError | undefined;
  icmsValidityStartDate?: FieldError | undefined;
  icmsValidityEndDate?: FieldError | undefined;
  icmsRangesFee?: FieldErrors | undefined;
};

const icmsConsumerUnitConsumptionClassOptions = [
  {
    key: 'COMMERCIAL',
    value: 'COMERCIAL',
  },
  {
    key: 'RESIDENTIAL',
    value: 'RESIDENCIAL',
  },
  {
    key: 'INDUSTRIAL',
    value: 'INDUSTRIAL',
  },
];

type Field =
  | 'icmsCountryState'
  | 'icmsConsumerUnitConsumptionClass'
  | 'icmsValidity'
  | 'icmsRangesFee';

export const FORM_FIELDS: Field[] = [
  'icmsCountryState',
  'icmsConsumerUnitConsumptionClass',
  'icmsValidity',
  'icmsRangesFee',
];

export default function IcmsTaxFormFields({
  errors,
  register,
  control,
  disableCountryStateSelect,
  disableConsumerUnitConsumptionClassSelect,
}: {
  errors: FormErrors;
  register: UseFormRegister<FormFields>;
  control: Control<FormFields>;
  disableCountryStateSelect?: boolean;
  disableConsumerUnitConsumptionClassSelect?: boolean;
}) {
  const { fields, append } = useFieldArray({
    name: 'icmsRangesFee',
    control,
  });

  useEffect(() => {
    const icmsRangesFee = Array(5)
      .fill(0)
      .map((_, i) => ({
        consumptionRangePercentage: '',
        consumptionKwhStartRange: '',
        consumptionKwhEndRange: '',
      }));

    append(icmsRangesFee);
  }, [append]);

  return (
    <>
      <div className="grid grid-cols-3 gap-4">
        <Controller
          name="icmsCountryState"
          control={control}
          rules={{ required: 'Campo obrigatório' }}
          render={({ field }) => (
            <CountryStateSelectField
              required
              {...omit(field, 'ref')}
              error={errors.icmsCountryState?.message}
              label="Estado"
              id="icmsCountryState"
              disabled={disableCountryStateSelect}
            />
          )}
        />

        <Controller
          name="icmsConsumerUnitConsumptionClass"
          control={control}
          rules={{ required: 'Campo obrigatório' }}
          render={({ field }) => (
            <SelectField
              required
              {...omit(field, 'ref')}
              error={errors.icmsConsumerUnitConsumptionClass?.message}
              label="Tipo de ICMS"
              emptyOptionLabel="Selecione uma classe de consumo"
              id="icmsConsumerUnitConsumptionClass"
              options={icmsConsumerUnitConsumptionClassOptions}
              disabled={disableConsumerUnitConsumptionClassSelect}
            />
          )}
        />

        <div className="flex row">
          <Controller
            render={({ field }) => (
              <DatePickerFieldDobleView
                required
                {...omit(field, 'ref')}
                error={{
                  startDateInput: errors.icmsValidityStartDate?.message,
                  endDateInput: errors.icmsValidityEndDate?.message,
                }}
                id="icmsValidity"
                label="Inicio da validade"
                endLabel="Fim da validade"
                selectRange
              />
            )}
            name="icmsValidity"
            control={control}
            rules={{ required: 'Campo obrigatório' }}
          />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4">
        <div>
          {fields.map((field, index) => {
            return (
              <div key={field.id}>
                <p className="text-gray-dark400 font-bold text-sm pt-8 pb-2">
                  {`${index + 1}º Faixa`}
                </p>
                <div className="grid grid-cols-3 gap-4">
                  <TextField
                    required
                    error={errors?.icmsRangesFee?.message}
                    label="Kwh Inicial"
                    id={`icmsRangesFee.${index}.consumptionKwhStartRange`}
                    {...register(`icmsRangesFee.${index}.consumptionKwhStartRange`, {
                      required: 'Campo obrigatório',
                    })}
                    placeholder="Kwh"
                    data-testid={`consumption-kwh-start-range-${index}`}
                  />

                  <TextField
                    required
                    error={errors?.icmsRangesFee?.message}
                    label="Kwh Final"
                    id={`icmsRangesFee.${index}.consumptionKwhEndRange`}
                    {...register(`icmsRangesFee.${index}.consumptionKwhEndRange`, {
                      required: 'Campo obrigatório',
                    })}
                    placeholder="Kwh"
                    data-testid={`consumption-kwh-end-range-${index}`}
                  />

                  <TextField
                    required
                    error={errors?.icmsRangesFee?.message}
                    label="Porcentagem"
                    id={`icmsRangesFee.${index}.consumptionRangePercentage`}
                    {...register(
                      `icmsRangesFee.${index}.consumptionRangePercentage`,
                      {
                        required: 'Campo obrigatório',
                      }
                    )}
                    placeholder="%"
                    data-testid={`consumption-kwh-range-percentage-${index}`}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
