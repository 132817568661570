import { FieldError, UseFormRegister } from 'react-hook-form';
import { Button } from 'ui';

import TextField from 'ui/form/TextField';

type Field =
  | 'cooperativeMemberLegalName'
  | 'cooperativeMemberDocumentId'
  | 'consumerUnitPowerDistributionUnitIdentifier';

export const FORM_FIELDS: Field[] = [
  'cooperativeMemberLegalName',
  'cooperativeMemberDocumentId',
  'consumerUnitPowerDistributionUnitIdentifier',
];
export type FormFields = {
  cooperativeMemberLegalName: string;
  cooperativeMemberDocumentId: string;
  consumerUnitPowerDistributionUnitIdentifier: string;
};

export type FormErrors = {
  cooperativeMemberLegalName?: FieldError;
  cooperativeMemberDocumentId?: FieldError;
  consumerUnitPowerDistributionUnitIdentifier?: FieldError;
};

export default function CooperativeMemberFormFilters({
  errors,
  register,
  isLoading,
}: {
  errors: FormErrors;
  register: UseFormRegister<FormFields>;
  isLoading?: boolean;
}) {
  return (
    <div className="grid grid-cols-6 gap-4 pb-6">
      <TextField
        error={errors.cooperativeMemberLegalName?.message}
        label="Razão Social"
        id="cooperativeMemberLegalName"
        {...register('cooperativeMemberLegalName')}
        placeholder="Digitar"
      />
      <TextField
        error={errors.cooperativeMemberDocumentId?.message}
        label="Documento"
        id="cooperativeMemberDocumentId"
        {...register('cooperativeMemberDocumentId')}
        placeholder="Digitar"
      />
      <TextField
        label="UC"
        id="consumerUnitPowerDistributionUnitIdentifier"
        {...register('consumerUnitPowerDistributionUnitIdentifier')}
        placeholder="Digitar"
      />

      <Button type="submit" size="sm" disabled={isLoading} className="self-end w-22">
        Filtrar
      </Button>
    </div>
  );
}
