import { CustomApportionmentCycleConsumerUnit } from 'customApportionmentCycleConsumerUnit/models/customApportionmentCycleCosumerUnits';
import { TableBodyColumn } from 'ui/components/table';
import TableBodyRow from 'ui/components/table/TableBodyRow';
import { AiFillEye } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';

type Props = {
  customApportionmentCycleConsumerUnit: CustomApportionmentCycleConsumerUnit;
  index: React.Key | null | undefined;
};

interface ApportionmentCycleConsumerUnitReferenceMonthFormatConfig {
  locale: string;
  options: Intl.DateTimeFormatOptions;
}

const APPORTIONMENT_CYCLE_CONSUMER_UNIT_FORMAT_CONFIG: ApportionmentCycleConsumerUnitReferenceMonthFormatConfig =
  {
    locale: 'pt-BR',
    options: { month: '2-digit', year: 'numeric' },
  };

const DASHBOARD_APPORTIONMENTS_CYCLE_ROUTE =
  '/dashboard/billing-cycle/apportionment-cycle/credit-distribution';

const formatValidityDate = (date: Date) => {
  const currentDate = new Date(date);
  currentDate.setDate(currentDate.getDate() + 1);
  return currentDate.toLocaleDateString(
    APPORTIONMENT_CYCLE_CONSUMER_UNIT_FORMAT_CONFIG.locale,
    APPORTIONMENT_CYCLE_CONSUMER_UNIT_FORMAT_CONFIG.options
  );
};

export default function CustomApportionmentCycleConsumerUnitItem({
  customApportionmentCycleConsumerUnit: {
    apportionmentCycleConsumerUnitCount,
    apportionmentCycleConsumerUnitReferenceMonth,
    generationUnitName,
    powerDistributionUnitName,
  },
  index,
}: Props): JSX.Element {
  const history = useHistory();

  return (
    <TableBodyRow
      dataTestId={`custom-apportionment-cycle-consumer-unit-row-${index}`}
    >
      <TableBodyColumn className="font-bold">{generationUnitName}</TableBodyColumn>
      <TableBodyColumn className="font-normal">
        {powerDistributionUnitName}
      </TableBodyColumn>
      <TableBodyColumn className="font-bold">
        {apportionmentCycleConsumerUnitCount}
      </TableBodyColumn>
      <TableBodyColumn className="font-normal">
        {formatValidityDate(apportionmentCycleConsumerUnitReferenceMonth)}
      </TableBodyColumn>
      <TableBodyColumn className="font-normal flex items-center justify-center">
        <AiFillEye
          className="cursor-pointer text-gray-dark500 hover:text-gray-dark400"
          size={20}
          onClick={() => {
            history.push(DASHBOARD_APPORTIONMENTS_CYCLE_ROUTE);
          }}
        />
      </TableBodyColumn>
    </TableBodyRow>
  );
}
