import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import has from 'lodash/has';
import { useEffect, useRef, useState } from 'react';
import { ErrorOption, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ApportiomentCycleConsumerUnitFormFields, {
  FormFields,
} from 'apportionmentCycles/components/apportionmentCycleConsumerUnits/form/ApportiomentCycleConsumerUnitFormFields';
import {
  ApportionmentCycleConsumerUnitCreated,
  ApportionmentCycleConsumerUnitMutationVariables,
  APPORTIONMENT_CYCLE_CONSUMER_UNIT_CREATE_MUTATION,
} from 'apportionmentCycles/graphql/apportionmentCycleConsumerUnitCreateMutation';
import { Button } from 'ui';
import { useLoading } from 'ui/contexts/overlay/Loading';
import { ToastProps } from 'ui/contexts/overlay/Toast';
import useToastContext from 'ui/hooks/useToast';
import { useCSVDownloader } from 'react-papaparse';

import { ModalFormProps } from 'ui/models/overlay';
import { formatDateToSubmitDateString, setFormError } from 'utils/form';
import {
  GENERATION_UNITS_QUERY,
  GenerationUnitsList,
} from 'generationUnits/graphql/generationUnitsQuery';
import { INITIAL_QUERY_STATE_CONFIG } from 'graphql/apollo/config';
import { GenerationUnit } from 'generationUnits/models/generationUnit';
import { DangerMessageProps } from 'apportionmentCycles/utils';

type ListState = {
  generationUnits: GenerationUnit[];
};

type Props = {
  onClickCloseModal: () => void;
  ModalOverlay: (any: ModalFormProps) => JSX.Element;
  formRefHandler: (formRef: HTMLFormElement | null) => void;
  ignoreWarnings: boolean;
  showDangerModal: Function;
  operation: 'INSERT' | 'REPLACE';
};

type ErrorProps = {
  message: string;
  type: 'ERROR' | 'WARNING';
};

type ApportionmentCycleConsumerUnitResult = {
  consumerUnitId: number;
  errors: ErrorProps[] | [];
};

const LIST_ERROR_TOAST: ToastProps = {
  text: 'Não foi possível carregar a lista de Unidades Geradoras',
  title: 'Algo deu errado!',
  variant: 'danger',
};

const CREATE_ERROR_TOAST: ToastProps = {
  text: 'Houve um erro ao tentar criar os rateios para o ciclo informado',
  title: 'Algo deu errado!',
  variant: 'danger',
};

const CREATE_SUCCESS_TOAST: ToastProps = {
  title: 'Successo!',
  variant: 'primary',
  text: 'Rateio cadastrado com sucesso.',
};

export default function CreateApportiomentCycleConsumerUnit({
  ModalOverlay,
  formRefHandler,
  onClickCloseModal,
  operation,
  ignoreWarnings,
  showDangerModal,
}: Props) {
  const [listState, setListState] = useState<ListState>({
    generationUnits: [],
  });

  const formRef = useRef(null);
  const [operationResult, setOperationResult] = useState(operation);
  const [ignoreWarningsResult, setIgnoreWarningsResult] = useState(ignoreWarnings);

  const { CSVDownloader, Type } = useCSVDownloader();

  const csvDownloaderTemplate = [
    {
      'Unidade Consumidora': '12345 (Exemplo)',
      'Percentual de Rateio': '1.25 (Exemplo)',
    },
  ];

  const {
    register,
    setError,
    clearErrors,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormFields>();
  const { showLoading, closeLoading, LoadingOverlay } = useLoading();
  const { addToast } = useToastContext();
  const { t } = useTranslation();

  const [generationUnitList, { data, loading, error, refetch }] =
    useLazyQuery<GenerationUnitsList>(
      GENERATION_UNITS_QUERY,
      INITIAL_QUERY_STATE_CONFIG
    );

  const [apportionmentCycleConsumerUnitCreateMutation, { loading: loadingCreate }] =
    useMutation<
      ApportionmentCycleConsumerUnitCreated,
      ApportionmentCycleConsumerUnitMutationVariables
    >(APPORTIONMENT_CYCLE_CONSUMER_UNIT_CREATE_MUTATION, {
      onError(error: ApolloError) {
        if (has(error.graphQLErrors[0], 'details') || error) {
          onClickCloseModal();

          addToast(CREATE_ERROR_TOAST);
        }

        setFormError(
          error,
          (field: string, error: ErrorOption) => {
            setError(field as keyof FormFields, error);

            setTimeout(() => clearErrors(), 2500);
          },
          t
        );
      },
      onCompleted(data: any) {
        const results =
          data?.apportionmentCycleConsumerUnitCreate
            ?.apportionmentCycleConsumerUnitResults;

        const dangerResult: DangerMessageProps[] = results
          .map(
            (result: ApportionmentCycleConsumerUnitResult) =>
              result.errors.length > 0 &&
              result.errors.map((error: ErrorProps) => ({
                'ERRO/AVISO': error.type === 'ERROR' ? 'ERRO' : 'AVISO',
                IDENTIFICAÇÃO:
                  Number(result.consumerUnitId) === 0
                    ? 'GERAL'
                    : `UC ${result.consumerUnitId}`,
                'DESCRIÇÃO DA MENSAGEM': t(error.message),
              }))
          )
          .filter((result: DangerMessageProps[]) => result.length > 0)
          .flat();

        if (dangerResult.length > 0) {
          showDangerModal(dangerResult);
        } else {
          onClickCloseModal();

          addToast(CREATE_SUCCESS_TOAST);
        }
      },
    });

  const onSubmit: SubmitHandler<FormFields> = (
    apportionmentCycleConsumerUnitCreateInput
  ) => {
    const apportionmentCycleConsumerUnitReferenceMonth =
      apportionmentCycleConsumerUnitCreateInput.apportionmentCycleConsumerUnitReferenceMonth;
    const convertApportionmentCycleConsumerUnitReferenceMonthTofirstDay = new Date(
      apportionmentCycleConsumerUnitReferenceMonth.getFullYear(),
      apportionmentCycleConsumerUnitReferenceMonth.getMonth(),
      1
    );

    apportionmentCycleConsumerUnitCreateMutation({
      variables: {
        apportionmentCycleConsumerUnitFileMetadata:
          apportionmentCycleConsumerUnitCreateInput
            .apportionmentCycleConsumerUnitFileMetadata['0'],
        apportionmentCycleConsumerUnitCreateInput: {
          generationUnitId:
            apportionmentCycleConsumerUnitCreateInput.generationUnitId,
          apportionmentCycleConsumerUnitReferenceMonth: formatDateToSubmitDateString(
            String(convertApportionmentCycleConsumerUnitReferenceMonthTofirstDay)
          ),
          ignoreWarnings: ignoreWarningsResult,
          operation: operationResult,
        },
      },
    });
  };

  const isLoading = loadingCreate || loading;

  useEffect(() => {
    formRefHandler(formRef.current);
  }, [formRefHandler]);

  useEffect(() => {
    setOperationResult(operation);
  }, [operation]);

  useEffect(() => {
    setIgnoreWarningsResult(ignoreWarnings);
  }, [ignoreWarnings]);

  useEffect(() => {
    if (error) {
      addToast(LIST_ERROR_TOAST);
    }
  }, [addToast, error]);

  useEffect(() => {
    if (isLoading) {
      showLoading();
      return;
    }

    closeLoading();
  }, [isLoading, showLoading, closeLoading]);

  useEffect(() => {
    generationUnitList();
  }, [generationUnitList]);

  useEffect(() => {
    if (isLoading) {
      showLoading();
      return;
    }

    if (data) {
      setListState({
        generationUnits: data.generationUnits.entries,
      });
    }

    closeLoading();
  }, [closeLoading, data, isLoading, showLoading]);

  useEffect(() => {
    refetch && refetch();

    generationUnitList();
  }, [generationUnitList, refetch]);

  return (
    <ModalOverlay title="Cadastrar novo rateio" variant="primary">
      <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
        <ApportiomentCycleConsumerUnitFormFields
          errors={errors}
          control={control}
          register={register}
          generationUnits={listState.generationUnits}
        />

        <div className="flex items-center justify-start mt-8 w-full gap-2">
          <label className="w-full text-gray-dark500 text-xs underline cursor-pointer">
            <CSVDownloader
              type={Type.Link}
              filename={'template'}
              bom={true}
              config={{
                delimiter: ';',
              }}
              data={csvDownloaderTemplate}
            >
              Baixar Template
            </CSVDownloader>
          </label>

          <div className="flex flex-row-reverse">
            <Button type="submit" variant="primaryGreen" size="md">
              <p className="font-bold">Carregar</p>
            </Button>
            <Button
              variant="primaryGray"
              className="mr-2"
              size="md"
              onClick={onClickCloseModal}
            >
              <p className="font-medium">Cancelar</p>
            </Button>
          </div>
        </div>
      </form>

      <LoadingOverlay />
    </ModalOverlay>
  );
}
