import { useEffect, useState } from 'react';
import { has } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { updateCacheById } from 'graphql/apollo/cache';
import { ErrorOption, SubmitHandler, useForm } from 'react-hook-form';
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';

import { Button } from 'ui';
import useToastContext from 'ui/hooks/useToast';
import { useLoading } from 'ui/contexts/overlay/Loading';
import { useConfirm } from 'ui/contexts/overlay/Confirm';
import { OverlayConfig, OverlayProps } from 'ui/models/overlay';
import { DEFAULT_OVERLAY_CONFIG, ToastProps } from 'ui/contexts/overlay/Toast';

import { setFormError } from 'utils/form';

import { Page } from 'dashboard/components/dashboard/Breadcrumbs';
import { Dashboard, DashboardMainHeaderForm } from 'dashboard/components/dashboard';

import GenerationUnitFormFields, {
  FormFields,
  FORM_FIELDS,
} from 'generationUnits/components/form/GenerationUnitFormFields';

import {
  GenerationUnitsTypename,
  GENERATION_UNIT_QUERY,
} from 'generationUnits/graphql/generationUnitQuery';

import {
  GenerationUnitUpdated,
  GenerationUnitUpdateMutationVariables,
  GENERATION_UNIT_UPDATE_MUTATION,
} from 'generationUnits/graphql/generationUnitUpdateMutation';

import {
  GenerationUnitDeleteMutationVariables,
  GENERATION_UNIT_DELETE_MUTATION,
} from 'generationUnits/graphql/generationUnitDeleteMutation';

import {
  POWER_DISTRIBUTION_UNITS_SELECT_QUERY,
  POWER_DISTRIBUTION_UNITS_SELECT_QUERY_VARIABLES,
  PowerDistributionUnitsSelectList,
} from 'powerDistributionUnits/graphql/powerDistributionUnitsSelectQuery';

import {
  COOPERATIVES_SELECT_QUERY,
  COOPERATIVES_SELECT_QUERY_VARIABLES,
  CooperativesSelectList,
} from 'cooperatives/graphql/cooperativesSelectQuery';

import {
  FINANCIAL_ACCOUNTS_QUERY,
  FINANCIAL_ACCOUNTS_SELECT_QUERY_VARIABLES,
  FinancialAccountList,
} from 'financialAccounts/graphql/financialAccountsQuery';

import DeleteButton from 'generationUnits/components/DeleteButton';

const DASHBOARD_GENERATION_UNITS_ROUTE =
  '/dashboard/business-entities/generation-units';

const BREADCRUMB_PAGES: Page[] = [
  {
    name: 'Unidades Geradoras',
    route: DASHBOARD_GENERATION_UNITS_ROUTE,
    current: false,
  },
  {
    name: 'Edição de Unidades Geradoras',
    route: null,
    current: true,
  },
];

const TITLE = 'Edição de Unidades Geradoras';

const UPDATE_ERROR_TOAST: ToastProps = {
  text: 'Houve um erro ao tentar atualizar a Unidade Geradora',
  title: 'Algo deu errado!',
  variant: 'danger',
};
const UPDATE_SUCCESS_TOAST: ToastProps = {
  title: 'Sucesso!',
  variant: 'primary',
  text: 'Sucesso ao atualizar a Unidade Geradora.',
};
const FETCH_ERROR_TOAST: ToastProps = {
  title: 'Algo deu errado!',
  variant: 'danger',
  text: 'Não foi possível carregar a Unidade Geradora',
};

export default function EditGenerationUnitsPage() {
  const { ConfirmOverlay, showConfirm, closeConfirm } = useConfirm();
  const { id } = useParams<{ id: string }>();
  const { push } = useHistory();
  const { addToast } = useToastContext();
  const { t } = useTranslation();

  const { showLoading, closeLoading, LoadingOverlay } = useLoading();

  const [generationUnitUpdateMutation, { loading: updateLoading }] = useMutation<
    GenerationUnitUpdated,
    GenerationUnitUpdateMutationVariables
  >(GENERATION_UNIT_UPDATE_MUTATION, {
    onError(error: ApolloError) {
      if (has(error.graphQLErrors[0], 'details')) {
        addToast(UPDATE_ERROR_TOAST);
      }

      setFormError(
        error,
        (field: string, error: ErrorOption) => {
          setError(field as keyof FormFields, error);

          setTimeout(() => clearErrors(), 2500);
        },
        t
      );
    },
    onCompleted() {
      addToast(UPDATE_SUCCESS_TOAST);
      push(DASHBOARD_GENERATION_UNITS_ROUTE);
    },
  });

  const [generationUnitDeleteMutation, { loading: deleteLoading }] =
    useMutation<GenerationUnitDeleteMutationVariables>(
      GENERATION_UNIT_DELETE_MUTATION,
      {
        onError() {
          closeConfirm();
          setConfirmOverlayProps(DEFAULT_OVERLAY_CONFIG);
          addToast(UPDATE_ERROR_TOAST);
        },
        onCompleted() {
          closeConfirm();
          setConfirmOverlayProps(DEFAULT_OVERLAY_CONFIG);
          addToast(UPDATE_SUCCESS_TOAST);
          push(DASHBOARD_GENERATION_UNITS_ROUTE);
        },
      }
    );

  const [
    generationUnitQuery,
    { data, loading: fetchGenerationUnitLoading, error: fetchGenerationUnitError },
  ] = useLazyQuery(GENERATION_UNIT_QUERY, {
    variables: {
      id: id,
    },
  });

  const [
    powerDistributionUnitsSelect,
    {
      data: powerDistributionUnits,
      loading: loadingPowerDistributionUnits,
      refetch: refetchPowerDistributionUnits,
    },
  ] = useLazyQuery<PowerDistributionUnitsSelectList>(
    POWER_DISTRIBUTION_UNITS_SELECT_QUERY,
    POWER_DISTRIBUTION_UNITS_SELECT_QUERY_VARIABLES
  );

  const [
    cooperativesSelect,
    {
      data: cooperatives,
      loading: loadingCooperatives,
      refetch: refetchCooperatives,
    },
  ] = useLazyQuery<CooperativesSelectList>(
    COOPERATIVES_SELECT_QUERY,
    COOPERATIVES_SELECT_QUERY_VARIABLES
  );

  const [
    financialAccountsSelect,
    {
      data: financialAccounts,
      loading: loadingFinancialAccounts,
      refetch: refetchFinancialAccounts,
    },
  ] = useLazyQuery<FinancialAccountList>(
    FINANCIAL_ACCOUNTS_QUERY,
    FINANCIAL_ACCOUNTS_SELECT_QUERY_VARIABLES
  );

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
    setValue,
    control,
  } = useForm<FormFields>();

  useEffect(() => {
    refetchPowerDistributionUnits && refetchPowerDistributionUnits();

    powerDistributionUnitsSelect();
  }, [powerDistributionUnitsSelect, refetchPowerDistributionUnits]);

  useEffect(() => {
    refetchCooperatives && refetchCooperatives();

    cooperativesSelect();
  }, [cooperativesSelect, refetchCooperatives]);

  useEffect(() => {
    refetchFinancialAccounts && refetchFinancialAccounts();

    financialAccountsSelect();
  }, [financialAccountsSelect, refetchFinancialAccounts]);

  useEffect(() => {
    if (fetchGenerationUnitError) {
      addToast(FETCH_ERROR_TOAST);
    }
  }, [addToast, fetchGenerationUnitError]);

  useEffect(() => {
    if (data?.generationUnit) {
      FORM_FIELDS.forEach((field) =>
        field !== 'generationUnitMonthlyCapacityFactor'
          ? setValue(field, data.generationUnit[field])
          : Object.keys(
              data.generationUnit.generationUnitMonthlyCapacityFactor
            ).forEach((month) =>
              setValue(
                `generationUnitMonthlyCapacityFactor.${month}` as unknown as keyof FormFields,
                data.generationUnit.generationUnitMonthlyCapacityFactor[month]
              )
            )
      );

      setValue('cooperativeId', data.generationUnit.cooperative.id);
      setValue('financialAccountId', data.generationUnit.financialAccount.id);
      setValue(
        'powerDistributionUnitId',
        data.generationUnit.powerDistributionUnit.id
      );
    }
  }, [data, setValue]);

  useEffect(() => {
    generationUnitQuery();
  }, [generationUnitQuery, id]);

  const [confirmOverlayProps, setConfirmOverlayProps] = useState<OverlayProps>(
    DEFAULT_OVERLAY_CONFIG
  );

  const isLoading = !!(
    deleteLoading ||
    updateLoading ||
    loadingCooperatives ||
    loadingFinancialAccounts ||
    fetchGenerationUnitLoading ||
    loadingPowerDistributionUnits
  );

  useEffect(() => {
    if (isLoading) {
      showLoading();
      return;
    }

    closeLoading();
  }, [isLoading, showLoading, closeLoading]);

  const onSubmit: SubmitHandler<FormFields> = (generationUnitUpdateInput) => {
    generationUnitUpdateMutation({
      variables: {
        generationUnitUpdateInput: {
          id: Number(id),
          generationUnitLegalName: generationUnitUpdateInput.generationUnitLegalName,
          generationUnitEnergySource:
            generationUnitUpdateInput.generationUnitEnergySource,
          generationUnitAddressPostalCode: Number(
            generationUnitUpdateInput.generationUnitAddressPostalCode
          ),
          generationUnitAddressCity:
            generationUnitUpdateInput.generationUnitAddressCity,
          generationUnitAddressStreet:
            generationUnitUpdateInput.generationUnitAddressStreet,
          generationUnitAddressDistrict:
            generationUnitUpdateInput.generationUnitAddressDistrict,
          generationUnitAddressState:
            generationUnitUpdateInput.generationUnitAddressState,
          generationUnitAddressComplement:
            generationUnitUpdateInput.generationUnitAddressComplement,
          powerDistributionUnitCredentialsUser:
            generationUnitUpdateInput.powerDistributionUnitCredentialsUser,
          powerDistributionUnitCredentialsPasswordInput:
            generationUnitUpdateInput.powerDistributionUnitCredentialsPasswordInput,
          generationUnitPowerCapacity:
            generationUnitUpdateInput.generationUnitPowerCapacity,
          financialAccountId: generationUnitUpdateInput.financialAccountId,
          generationUnitMonthlyCapacityFactor: {
            generationUnitJanCapacityFactor:
              generationUnitUpdateInput.generationUnitMonthlyCapacityFactor
                .generationUnitJanCapacityFactor,
            generationUnitFebCapacityFactor:
              generationUnitUpdateInput.generationUnitMonthlyCapacityFactor
                .generationUnitFebCapacityFactor,
            generationUnitMarCapacityFactor:
              generationUnitUpdateInput.generationUnitMonthlyCapacityFactor
                .generationUnitMarCapacityFactor,
            generationUnitAprCapacityFactor:
              generationUnitUpdateInput.generationUnitMonthlyCapacityFactor
                .generationUnitAprCapacityFactor,
            generationUnitMayCapacityFactor:
              generationUnitUpdateInput.generationUnitMonthlyCapacityFactor
                .generationUnitMayCapacityFactor,
            generationUnitJunCapacityFactor:
              generationUnitUpdateInput.generationUnitMonthlyCapacityFactor
                .generationUnitJunCapacityFactor,
            generationUnitJulCapacityFactor:
              generationUnitUpdateInput.generationUnitMonthlyCapacityFactor
                .generationUnitJulCapacityFactor,
            generationUnitAugCapacityFactor:
              generationUnitUpdateInput.generationUnitMonthlyCapacityFactor
                .generationUnitAugCapacityFactor,
            generationUnitSepCapacityFactor:
              generationUnitUpdateInput.generationUnitMonthlyCapacityFactor
                .generationUnitSepCapacityFactor,
            generationUnitOctCapacityFactor:
              generationUnitUpdateInput.generationUnitMonthlyCapacityFactor
                .generationUnitSepCapacityFactor,
            generationUnitNovCapacityFactor:
              generationUnitUpdateInput.generationUnitMonthlyCapacityFactor
                .generationUnitNovCapacityFactor,
            generationUnitDecCapacityFactor:
              generationUnitUpdateInput.generationUnitMonthlyCapacityFactor
                .generationUnitDecCapacityFactor,
          },
        },
      },
    });
  };

  const onConfirmDelete = () =>
    generationUnitDeleteMutation({
      variables: { id: id },
      update(cache) {
        updateCacheById(cache, Number(id), GenerationUnitsTypename);
      },
    });

  const onClickDelete = (overlayConfig: OverlayConfig) => {
    setConfirmOverlayProps({
      ...overlayConfig,
      onConfirm: onConfirmDelete,
    });

    showConfirm();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LoadingOverlay />
      <Dashboard
        dashboardHeader={<div className="h-10" />}
        dashboardMainHeaderTitle={
          <DashboardMainHeaderForm title={TITLE} breadcrumbPages={BREADCRUMB_PAGES}>
            {data?.generationUnit && (
              <>
                <DeleteButton onClick={onClickDelete} disabled={isLoading} />
                <Button type="submit" size="sm" disabled={isLoading}>
                  Salvar
                </Button>
              </>
            )}
            <ConfirmOverlay {...confirmOverlayProps} onCancel={closeConfirm} />
          </DashboardMainHeaderForm>
        }
      >
        {data?.generationUnit && (
          <GenerationUnitFormFields
            errors={errors}
            register={register}
            cooperatives={cooperatives?.cooperatives.entries}
            financialAccounts={financialAccounts?.financialAccounts.entries}
            powerDistributionUnits={
              powerDistributionUnits?.powerDistributionUnits.entries
            }
            control={control}
            disableFields
          />
        )}
      </Dashboard>
    </form>
  );
}
