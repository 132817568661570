import { ApportionmentCycleConsumerUnit } from 'apportionmentCycles/models/apportionmentCycleConsumerUnit';
import { TableBodyColumn } from 'ui/components/table';
import TableBodyRow from 'ui/components/table/TableBodyRow';

type Props = {
  apportionmentCycleConsumerUnit: ApportionmentCycleConsumerUnit;
};

export default function ApportionmentCycleConsumerUnitItem({
  apportionmentCycleConsumerUnit: {
    id,
    apportionmentCycleConsumerUnitPercentual,
    consumerUnit: {
      consumerUnitPowerDistributionUnitIdentifier,
      cooperativeMember: { cooperativeMemberLegalName },
    },
  },
}: Props): JSX.Element {
  return (
    <TableBodyRow dataTestId={`apportionment-cycle-row-id-${id}`}>
      <TableBodyColumn className="font-normal">{id}</TableBodyColumn>
      <TableBodyColumn className="font-normal">
        {consumerUnitPowerDistributionUnitIdentifier}
      </TableBodyColumn>
      <TableBodyColumn className="font-bold">
        {cooperativeMemberLegalName}
      </TableBodyColumn>
      <TableBodyColumn className="font-bold">
        {`${apportionmentCycleConsumerUnitPercentual} %`}
      </TableBodyColumn>
      <TableBodyColumn className="text-center">-</TableBodyColumn>
      <TableBodyColumn className="text-center">-</TableBodyColumn>
      <TableBodyColumn className="text-center">-</TableBodyColumn>
    </TableBodyRow>
  );
}
