import { FieldError, UseFormRegister, Control, Controller } from 'react-hook-form';
import omit from 'lodash/omit';
import { FormContainer } from 'ui';
import TextField from 'ui/form/TextField';
import SelectField from 'ui/form/SelectField';
import DatePickerFieldDobleView from 'ui/form/DatePickerFieldDobleView';
import { useEffect, useState } from 'react';
import { Option } from 'ui/components/form/SelectInput';
import {
  PowerDistributionUnitSelect,
  PowerDistributionUnitId,
} from 'powerDistributionUnits/models/powerDistributionUnit';

export type FormFields = {
  pisCofinsValidity: [Date, Date];
  pisCofinsPisFee: string;
  pisCofinsCofinsFee: string;
  powerDistributionUnitId: PowerDistributionUnitId;
};

export type FormErrors = {
  pisCofinsValidityStartAt?: FieldError | undefined;
  pisCofinsValidityEndAt?: FieldError | undefined;
  pisCofinsPisFee?: FieldError | undefined;
  pisCofinsCofinsFee?: FieldError | undefined;
  powerDistributionUnitId?: FieldError | undefined;
};

type Field =
  | 'pisCofinsValidity'
  | 'pisCofinsPisFee'
  | 'pisCofinsCofinsFee'
  | 'powerDistributionUnitId';

export const FORM_FIELDS: Field[] = [
  'pisCofinsValidity',
  'pisCofinsPisFee',
  'pisCofinsCofinsFee',
  'powerDistributionUnitId',
];

export default function PixCofinsTaxFormFields({
  errors,
  register,
  control,
  powerDistributionUnits,
  powerDistributionUnitValue,
  disablePowerDistributionUnitSelect,
}: {
  errors: FormErrors;
  register: UseFormRegister<FormFields>;
  control: Control<FormFields>;
  powerDistributionUnits?: PowerDistributionUnitSelect[];
  powerDistributionUnitValue?: string;
  disablePowerDistributionUnitSelect?: boolean;
}) {
  const [powerDistributionUnitOptions, setPowerDistributionUnitOptions] = useState<
    Option<string>[]
  >([]);
  useEffect(
    () =>
      setPowerDistributionUnitOptions([
        ...(powerDistributionUnits?.map((powerDistributionUnit) => ({
          key: `${powerDistributionUnit.id}`,
          value: powerDistributionUnit.powerDistributionUnitLegalName,
        })) || []),
      ]),
    [powerDistributionUnits]
  );

  return (
    <FormContainer>
      <Controller
        name="powerDistributionUnitId"
        control={control}
        rules={{ required: false }}
        render={({ field }) => (
          <SelectField
            {...omit(field, 'ref', 'value')}
            error={errors.powerDistributionUnitId?.message}
            label="Concessionária"
            id="cooperativeHeadquarterId"
            options={powerDistributionUnitOptions}
            value={field.value ? `${field.value}` : undefined}
            disabled={disablePowerDistributionUnitSelect}
            required={false}
            emptyOptionLabel="Nenhuma"
          />
        )}
      />

      <div className="flex flex-col">
        <div className="grid grid-cols-2 gap-x-7">
          <TextField
            required
            error={errors?.pisCofinsPisFee?.message}
            label="PIS"
            id={'pisCofinsPisFee'}
            {...register('pisCofinsPisFee', { required: 'Campo obrigatório' })}
            placeholder="% 0.00"
            maxLength={4}
          />

          <TextField
            required
            error={errors?.pisCofinsCofinsFee?.message}
            label="COFINS"
            id="pisCofinsCofinsFee"
            {...register('pisCofinsCofinsFee', {
              required: 'Campo obrigatório',
            })}
            placeholder="% 0.00"
            maxLength={4}
          />
        </div>
      </div>

      <Controller
        render={({ field }) => (
          <DatePickerFieldDobleView
            required
            {...omit(field, 'ref')}
            error={{
              startDateInput: errors.pisCofinsValidityStartAt?.message,
              endDateInput: errors.pisCofinsValidityEndAt?.message,
            }}
            id="pis"
            label="Inicio da validade"
            endLabel="Fim da validade"
            selectRange
          />
        )}
        name="pisCofinsValidity"
        control={control}
        rules={{ required: 'Campo obrigatório' }}
      />
    </FormContainer>
  );
}
