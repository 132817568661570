import { gql } from '@apollo/client';
import {
  TariffGroupModalityType,
  ConsumptionGroups,
  DemandCost,
  ConsumptionCost,
  TariffGroup,
} from 'tariffGroups/models/tariffGroup';

export interface TariffGroupCreateMutationInput {
  tariffGroupConsumptionCosts: ConsumptionCost;
  tariffGroupDemandCosts?: DemandCost;
  tariffGroupConsumptionGroup: ConsumptionGroups;
  tariffGroupModality: TariffGroupModalityType;
  tariffGroupValidityEndDate: Date;
  tariffGroupValidityStartDate: Date;
  powerDistributionUnitId: number;
}

export interface TariffGroupMutationVariables {
  powerDistributionUnitTariffGroupCreateInput: TariffGroupCreateMutationInput;
}

export interface TariffGroupCreated {
  powerDistributionUnitTariffGroupCreate: TariffGroup;
}

export const TARIFF_GROUP_CREATE_MUTATION = gql`
  mutation powerDistributionUnitTariffGroupCreate(
    $powerDistributionUnitTariffGroupCreateInput: PowerDistributionUnitTariffGroupCreateInput!
  ) {
    powerDistributionUnitTariffGroupCreate(
      input: $powerDistributionUnitTariffGroupCreateInput
    ) {
      id
      tariffGroupConsumptionCosts {
        energyTariff
        energyUsageTariffOnPeak
        energyUsageTariffOffPeak
        energyDistributionServiceTariff
        energyDistributionServiceTariffOnPeak
        energyDistributionServiceTariffOffPeak
      }
      tariffGroupDemandCosts {
        all
        offPeak
        onPeak
      }
      tariffGroupConsumptionGroup
      tariffGroupModality
      tariffGroupValidityEndDate
      tariffGroupValidityStartDate
      powerDistributionUnit {
        id
        powerDistributionUnitLegalName
      }
    }
  }
`;
