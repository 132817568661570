import { Button } from 'ui';
import { Dashboard, DashboardMainHeaderForm } from 'dashboard/components/dashboard';
import { useConfirm } from 'ui/contexts/overlay/Confirm';
import { Page } from 'dashboard/components/dashboard/Breadcrumbs';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import useToastContext from 'ui/hooks/useToast';
import { ErrorOption, SubmitHandler, useForm } from 'react-hook-form';
import PowerDistibutionFormFields, {
  FormFields,
  FORM_FIELDS,
} from 'powerDistributionUnits/components/form/PowerDistributionUnitFields';
import { useLoading } from 'ui/contexts/overlay/Loading';
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  PowerDistributionUnitUpdated,
  PowerDistributionUnitMutationVariables,
  POWER_DISTRIBUTION_UNIT_UPDATE_MUTATION,
} from 'powerDistributionUnits/graphql/powerDistributionUnitUpdateMutation';
import { setFormError } from 'utils/form';
import {
  powerDistributionUnitTypename,
  POWER_DISTRIBUTION_UNIT_QUERY,
} from 'powerDistributionUnits/graphql/powerDistributionUnitQuery';
import { DEFAULT_OVERLAY_CONFIG, ToastProps } from 'ui/contexts/overlay/Toast';
import {
  PowerDistributionUnitDeleteMutationVariables,
  POWER_DISTRIBUTION_UNIT_DELETE_MUTATION,
} from 'powerDistributionUnits/graphql/powerDistributionUnitDeleteMutaiton';
import { OverlayConfig, OverlayProps } from 'ui/models/overlay';
import DeleteButton from 'powerDistributionUnits/components/DeleteButton';
import { has } from 'lodash';
import { updateCacheById } from 'graphql/apollo/cache';

const DASHBOARD_POWER_DISTRIBUTION_UNITS_ROUTE =
  '/dashboard/business-entities/power-distribution-units';

const BREADCRUMB_PAGES: Page[] = [
  {
    name: 'Concessionária',
    route: DASHBOARD_POWER_DISTRIBUTION_UNITS_ROUTE,
    current: false,
  },
  {
    name: 'Edição de Concessionária',
    route: null,
    current: true,
  },
];

const TITLE = 'Edição de Concessionária';

const UPDATE_ERROR_TOAST: ToastProps = {
  text: 'Houve um erro ao tentar atualizar a Concessionária',
  title: 'Algo deu errado!',
  variant: 'danger',
};
const UPDATE_SUCCESS_TOAST: ToastProps = {
  title: 'Sucesso!',
  variant: 'primary',
  text: 'Sucesso ao atualizar a Concessionária.',
};
const FETCH_ERROR_TOAST: ToastProps = {
  title: 'Algo deu errado!',
  variant: 'danger',
  text: 'Não foi possível carregar a Concessionária',
};

export default function EditPowerDistributionUnitsPage() {
  const { ConfirmOverlay, showConfirm, closeConfirm } = useConfirm();
  const { id } = useParams<{ id: string }>();
  const { push } = useHistory();
  const { addToast } = useToastContext();
  const { t } = useTranslation();

  const { showLoading, closeLoading, LoadingOverlay } = useLoading();

  const [powerDistributionUnitUpdateMutation, { loading: updateLoading }] =
    useMutation<
      PowerDistributionUnitUpdated,
      PowerDistributionUnitMutationVariables
    >(POWER_DISTRIBUTION_UNIT_UPDATE_MUTATION, {
      onError(error: ApolloError) {
        if (has(error.graphQLErrors[0], 'details')) {
          addToast(UPDATE_ERROR_TOAST);
        }

        setFormError(
          error,
          (field: string, error: ErrorOption) => {
            setError(field as keyof FormFields, error);

            setTimeout(() => clearErrors(), 2500);
          },
          t
        );
      },
      onCompleted() {
        addToast(UPDATE_SUCCESS_TOAST);
        push(DASHBOARD_POWER_DISTRIBUTION_UNITS_ROUTE);
      },
    });

  const [powerDistributionUnitDeleteMutation, { loading: deleteLoading }] =
    useMutation<PowerDistributionUnitDeleteMutationVariables>(
      POWER_DISTRIBUTION_UNIT_DELETE_MUTATION,
      {
        onError() {
          closeConfirm();
          setConfirmOverlayProps(DEFAULT_OVERLAY_CONFIG);
          addToast(UPDATE_ERROR_TOAST);
        },
        onCompleted() {
          closeConfirm();
          setConfirmOverlayProps(DEFAULT_OVERLAY_CONFIG);
          addToast(UPDATE_SUCCESS_TOAST);
          push(DASHBOARD_POWER_DISTRIBUTION_UNITS_ROUTE);
        },
      }
    );

  const [
    powerDistributionUnitQuery,
    {
      data,
      loading: fetchPowerDistributionUnitLoading,
      error: fetchPowerDistributionUnitError,
    },
  ] = useLazyQuery(POWER_DISTRIBUTION_UNIT_QUERY, {
    variables: {
      powerDistributionUnitId: id,
    },
  });

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    control,
    formState: { errors },
    setValue,
  } = useForm<FormFields>();

  useEffect(() => {
    if (fetchPowerDistributionUnitError) {
      addToast(FETCH_ERROR_TOAST);
    }
  }, [addToast, fetchPowerDistributionUnitError]);

  useEffect(() => {
    if (data?.powerDistributionUnit) {
      FORM_FIELDS.forEach((field) =>
        setValue(field, data?.powerDistributionUnit[field])
      );
    }
  }, [data, setValue]);

  useEffect(() => {
    powerDistributionUnitQuery();
  }, [powerDistributionUnitQuery, id]);

  const [confirmOverlayProps, setConfirmOverlayProps] = useState<OverlayProps>(
    DEFAULT_OVERLAY_CONFIG
  );

  const isLoading = !!(
    deleteLoading ||
    updateLoading ||
    fetchPowerDistributionUnitLoading
  );

  useEffect(() => {
    if (isLoading) {
      showLoading();
      return;
    }

    closeLoading();
  }, [isLoading, showLoading, closeLoading]);

  const onSubmit: SubmitHandler<FormFields> = (powerDistributionUnitUpdateInput) => {
    powerDistributionUnitUpdateMutation({
      variables: {
        powerDistributionUnitUpdateInput: {
          id: Number(id),
          powerDistributionUnitCountryState:
            powerDistributionUnitUpdateInput.powerDistributionUnitCountryState,
          powerDistributionUnitDocumentId:
            powerDistributionUnitUpdateInput.powerDistributionUnitDocumentId,
          powerDistributionUnitLegalName:
            powerDistributionUnitUpdateInput.powerDistributionUnitLegalName,
        },
      },
    });
  };

  const onConfirmDelete = () =>
    powerDistributionUnitDeleteMutation({
      variables: { id: Number(id) },
      update(cache) {
        updateCacheById(cache, Number(id), powerDistributionUnitTypename);
      },
    });

  const onClickDelete = (overlayConfig: OverlayConfig) => {
    setConfirmOverlayProps({
      ...overlayConfig,
      onConfirm: onConfirmDelete,
    });

    showConfirm();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LoadingOverlay />
      <Dashboard
        dashboardHeader={<div className="h-10" />}
        dashboardMainHeaderTitle={
          <DashboardMainHeaderForm title={TITLE} breadcrumbPages={BREADCRUMB_PAGES}>
            {data?.powerDistributionUnit && (
              <>
                {' '}
                <DeleteButton onClick={onClickDelete} disabled={isLoading} />
                <Button type="submit" size="sm" disabled={isLoading}>
                  Salvar
                </Button>
              </>
            )}
            <ConfirmOverlay {...confirmOverlayProps} onCancel={closeConfirm} />
          </DashboardMainHeaderForm>
        }
      >
        {data?.powerDistributionUnit && (
          <PowerDistibutionFormFields
            errors={errors}
            register={register}
            control={control}
          />
        )}
      </Dashboard>
    </form>
  );
}
