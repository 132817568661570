import { gql } from '@apollo/client';
import { CustomApportionmentCycleConsumerUnit } from 'customApportionmentCycleConsumerUnit/models/customApportionmentCycleCosumerUnits';

interface CustomApportionmentCycleConsumerUnitConnection {
  afterCursor: string | null;
  beforeCursor: string | null;
  entries: CustomApportionmentCycleConsumerUnit[];
}

export interface CustomApportionmentCycleConsumerUnitsList {
  customApportionmentCycleConsumerUnits: CustomApportionmentCycleConsumerUnitConnection;
}

export const CustomApportionmentCycleConsumerUnitsTypename =
  'CustomApportionmentCycleConsumerUnitConnection';

export const CUSTOM_APPORTIONMENT_CYCLE_CONSUMER_UNITS_QUERY = gql`
  query customApportionmentCycleConsumerUnits(
    $after: String
    $before: String
    $pageSize: Int
  ) {
    customApportionmentCycleConsumerUnits(
      after: $after
      before: $before
      pageSize: $pageSize
    ) {
      entries {
        apportionmentCycleConsumerUnitCount
        apportionmentCycleConsumerUnitReferenceMonth
        generationUnitName
        powerDistributionUnitName
      }
      beforeCursor
      afterCursor
    }
  }
`;
