import SelectField from 'ui/form/SelectField';
import TextField from 'ui/form/TextField';
import {
  Control,
  Controller,
  FieldError,
  UseFormRegister,
  useWatch,
} from 'react-hook-form';
import { CooperativeSelect } from 'cooperatives/models/cooperative';
import {
  CooperativeMemberEntityType,
  CooperativeMemberModalityType,
  CooperativeMemberSelect,
} from 'cooperatives/models/cooperativeMember';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Option } from 'ui/components/form/SelectInput';
import RadioField from 'ui/form/RadioField';
import omit from 'lodash/omit';
import { uniq } from 'lodash';

type Field =
  | 'cooperativeId'
  | 'cooperativeMemberLegalName'
  | 'cooperativeMemberDocumentId'
  | 'cooperativeMemberEntityType'
  | 'cooperativeMemberModalityType';

export const FORM_FIELDS: Field[] = [
  'cooperativeId',
  'cooperativeMemberLegalName',
  'cooperativeMemberDocumentId',
  'cooperativeMemberEntityType',
  'cooperativeMemberModalityType',
];

export type FormFields = {
  cooperativeId: number;
  cooperativeMemberLegalName: string;
  cooperativeMemberDocumentId: string;
  cooperativeMemberEntityType: CooperativeMemberEntityType;
  cooperativeMemberModalityType: CooperativeMemberModalityType | null;
};

export type FormErrors = {
  cooperativeId?: FieldError;
  cooperativeMemberLegalName?: FieldError;
  cooperativeMemberDocumentId?: FieldError;
  cooperativeMemberEntityType?: FieldError;
  cooperativeMemberModalityType?: FieldError;
};

export default function CooperativeFormFields({
  errors,
  control,
  register,
  cooperatives,
  cooperativeMembers,
  disabledCooperative,
}: {
  errors: FormErrors;
  control: Control<FormFields>;
  disabledCooperative?: boolean;
  cooperatives?: CooperativeSelect[];
  register: UseFormRegister<FormFields>;
  cooperativeMembers?: CooperativeMemberSelect[];
}) {
  const [cooperativeId, cooperativeMemberModalityType, cooperativeMemberEntityType] =
    useWatch({
      control,
      name: [
        'cooperativeId',
        'cooperativeMemberModalityType',
        'cooperativeMemberEntityType',
      ],
    });

  const [cooperativeOptions, setCooperativeOptions] = useState<Option<number>[]>([]);

  useEffect(
    () =>
      setCooperativeOptions(
        cooperatives?.map((cooperative) => ({
          key: cooperative.id,
          value: cooperative.cooperativeLegalName,
        })) || []
      ),
    [cooperatives]
  );

  const cooperativeMemberModalityTypeCompanySelected =
    cooperativeMemberEntityType === CooperativeMemberEntityType.COMPANY;

  const disabledFields = useMemo(() => {
    const disabledMap = {
      cooperativeMemberModalityType: cooperativeMemberModalityTypeCompanySelected,
    };

    return uniq(
      Object.entries(disabledMap)
        .filter(([_key, value]) => value)
        .map(([key]) => key)
    );
  }, [cooperativeMemberModalityTypeCompanySelected]);

  const isDisabled = useCallback(
    (field) => disabledFields.includes(field),
    [disabledFields]
  );

  const modalityType = useMemo(() => {
    const modalityTypeOptions = [
      { key: 'BRANCH', value: 'FILIAL' },
      { key: 'HEADQUARTER', value: 'MATRIZ' },
    ];

    return cooperativeMemberModalityTypeCompanySelected ? modalityTypeOptions : [];
  }, [cooperativeMemberModalityTypeCompanySelected]);

  return (
    <div className="grid grid-cols-2 gap-6">
      <div className="col-start-1 col-end-4">
        <Controller
          name="cooperativeMemberEntityType"
          control={control}
          rules={{ required: 'Campo obrigatório' }}
          render={({ field: { ref, onChange, value } }) => (
            <RadioField
              required
              {...omit(ref, 'ref', 'value')}
              error={errors.cooperativeMemberEntityType?.message}
              label="Entidade"
              id="cooperativeMemberEntityType"
              options={[
                {
                  label: 'Jurídica',
                  value: 'COMPANY',
                },
                {
                  label: 'Física',
                  value: 'INDIVIDUAL',
                },
              ]}
              onChange={onChange}
              value={value}
            />
          )}
        />
      </div>
      <div className="grid col-start-1 gap-6">
        <TextField
          required
          error={errors.cooperativeMemberLegalName?.message}
          label="Razão Social/Nome"
          id="cooperativeMemberLegalName"
          {...register('cooperativeMemberLegalName', {
            required: 'Campo obrigatório',
          })}
          placeholder="Ex.: Nex Energy Gestão de Energia"
        />
        <TextField
          required
          error={errors.cooperativeMemberDocumentId?.message}
          label="Documento"
          id="cooperativeMemberDocumentId"
          {...register('cooperativeMemberDocumentId', {
            required: 'Campo obrigatório',
          })}
          placeholder="Digite o documento CPF/CNPJ"
        />
      </div>
      <div className="grid col-start-2 gap-6">
        <Controller
          name="cooperativeId"
          control={control}
          rules={{ required: 'Campo obrigatório' }}
          render={({ field }) => (
            <SelectField
              {...omit(field, 'ref', 'value')}
              error={errors.cooperativeId?.message}
              label="Cooperativa"
              emptyOptionLabel="Selecione uma Cooperativa"
              id="cooperativeId"
              options={cooperativeOptions}
              value={cooperativeId}
              required={!disabledCooperative}
              disabled={disabledCooperative}
            />
          )}
        />
        <Controller
          control={control}
          name="cooperativeMemberModalityType"
          render={({ field }) => (
            <SelectField
              {...omit(field, 'ref', 'value')}
              label="Tipo"
              id="cooperativeMemberModalityType"
              value={cooperativeMemberModalityType}
              options={modalityType}
              error={errors.cooperativeMemberModalityType?.message}
              emptyOptionLabel="Selecione um tipo (Filial / Matriz)"
              disabled={!isDisabled('cooperativeMemberModalityType')}
            />
          )}
        />
      </div>
    </div>
  );
}
